<template>
<div ref="local" id="local" class="local-wrapper">
  <div class="palmpay-wrapper" v-loading.fullscreen="fullscreenLoading">
    <div class="content-wrapper" v-if="paymentInfo">
      <div v-if="paymentInfo.brandConfig && paymentInfo.brandConfig.logoImage" class="logos">
        <div class="merchant-logo">
          <img :src="paymentInfo.brandConfig.logoImage" style="height: 100%;" />
        </div>
      </div>
      <div class="info-wrapper">
        <div class="info pc">
          <div class="item">
            <div class="head">{{$t('message.palmpay.amount')}}:</div>
            <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
          </div>
        </div>
        <div class="info mobile">
          <div class="item">
            <div class="head">{{$t('message.palmpay.amount')}}:</div>
            <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
          </div>
        </div>
        <div class="order-info">
          <div class="order-top-tips">{{$t('message.palmpay.orderTips')}}</div>
          <div class="bank-name">{{$t('message.palmpay.bankName')}}</div>
          <div class="card-logo">
            <img v-if="paymentInfo.checkoutModelResp && paymentInfo.checkoutModelResp.logoUrl" :src="paymentInfo.checkoutModelResp.logoUrl" style="height: 100%;" />
          </div>
          <div class="ticket-info">
            <div class="ticket-title">
              <span>{{$t('message.palmpay.numberTitle')}}</span>
              <el-button class="button margin-left-12" type="text" @click="copyTicket">
                <span class="iconfont icon-rongqi"></span>
              </el-button>
            </div>
            <div class="ticket-box">
              <div class="ticket">{{ paymentInfo.presentContext.payerVirtualAccNo }}</div>
              <el-button class="button margin-left-12" type="text" @click="copyTicket">
                <span class="iconfont icon-rongqi"></span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <div v-if="leftTime" class="expire-time">{{$t('message.palmpay.expireText', { time: leftTime})}}</div>
          <el-button @click="copyTicket" class="button" type="primary">{{$t('message.palmpay.copyBtnText')}}</el-button>
        </div>
      </div>
    </div>
    <div v-if="showErrorMsg" class="error-msg">
      <img class="unknow" src="../../assets/images/unknow.png" />
      <div class="tips">{{ $t('message.dlocal.errorMsg') }}</div>
    </div>
  </div>
  <div v-if="showToast" class="toast-box">
    <span class="iconfont icon-chenggong"></span>
    <div class="toast-tips">{{$t('message.dlocal.copySuccess')}}</div>
  </div>
</div>
</template>
<script>
import { getLocalPaymentInfo } from '@/api/checkout';
import { copyString, getLeftTimeByExpire, getDevice } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      txnId: '',
      paymentInfo: null,
      showErrorMsg: false,
      showToast: false,
      leftTime: '',
    };
  },
  created() {
    this.txnId = this.$route.query.txnId;
    getLocalPaymentInfo(this.txnId).then((res) => {
      this.fullscreenLoading = false;
      if (res.respCode === '20000') {
        this.paymentInfo = res.data;
        if (res.data.brandConfig) {
          this.$store.dispatch('app/setBrandInfo', res.data.brandConfig);
        }
        if (!res.data) {
          this.showErrorMsg = true;
        } else {
          this.onCountdownTime();
        }
      } else {
        this.showErrorMsg = true;
      }
    }).catch(() => {
      this.showErrorMsg = true;
      this.fullscreenLoading = false;
    });
  },
  methods: {
    copyTicket() {
      const device = getDevice();
      if (device.device === 'WAP') {
        this.showToast = true;
        setTimeout(() => {
          this.showToast = false;
        }, 2000);
      } else {
        copyString(this.paymentInfo.presentContext.payerVirtualAccNo, this.$t('message.dlocal.copySuccess'));
      }
    },
    onCountdownTime() {
      const timer = setInterval(() => {
        this.leftTime = getLeftTimeByExpire(this.paymentInfo.presentContext.orderExpireMilliSec);
        if (this.leftTime === '00:00') {
          clearInterval(timer);
          window.location.href = this.paymentInfo.returnUrl;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.local-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: white;

  .toast-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 125px;
    min-height: 97px;
    background: rgba(0,0,0,0.8);
    border-radius: 4px;
    color: #FFFFFF;

    .icon-chenggong {
      font-size: 30px;
    }

    .toast-tips {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      margin-top: 10px;
    }
  }
}
.palmpay-wrapper {
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;
  .content-wrapper {
    padding: 0 15px;
    padding-top: 67px;
    .logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .card-logo {
        height: 60px;
      }
      .merchant-logo {
        height: 60px;
      }
    }
    .info-wrapper {
      text-align: center;
    }
    .info {
      background: white;
      height: 144px;
      &.mobile {
        display: none;
      }
      &.pc {
        display: block;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 144px;
      }
      .head {
        font-weight: 900;
        font-size: 18px;
        color: #27272B;
        line-height: 25px;
      }
      .content {
        font-weight: 900;
        font-size: 28px;
        color: #27272B;
        line-height: 38px;
        margin-top: 4px;
        max-width: 100%;
        white-space: nowrap;
      }
    }
    .order-info {
      width: 100%;
      background: #FAFAFA;
      padding: 40px;
      padding-bottom: 56px;
      text-align: left;
      margin-bottom: 30px;
      box-sizing: border-box;

      .order-top-tips {
        font-weight: 900;
        font-size: 20px;
        color: $color-text;
        line-height: 27px;
      }

      .bank-name {
        font-weight: 900;
        font-size: 20px;
        color: $color-text;
        line-height: 27px;
        margin-top: 40px;
        margin-bottom: 10px;
      }

      .card-logo {
        height: 70px;
        margin-bottom: 20px;
      }

      .ticket-info {
        .ticket-title {
          display: flex;
          align-items: center;
          font-weight: 900;
          font-size: 20px;
          color: $color-text;
          line-height: 27px;
        }
        .ticket-box {
          display: flex;
          align-items: center;

          .button {
            display: none;
          }
        }
        .ticket {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 70px;
          background: #FFFFFF;
          border-radius: 2px;
          border: 1px solid #616D7A;
          box-sizing: border-box;
          font-weight: 500;
          font-size: 32px;
          color: var(--color-primary);
        }
      }
    }
    .bottom-box {
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      .expire-time {
        font-weight: 500;
        font-size: 16px;
        color: #616D7A;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .button {
        width: 100%;
      }
    }
  }
  .error-msg {
    padding: 200px 15px 0;
    width: 690px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .unknow {
      width: 134px;
      height: auto;
    }
    .tips {
      font-weight: 500;
      font-size: 18px;
      color: #616D7A;
      line-height: 25px;
      text-align: left;
      margin-top: 40px;
    }
  }
}
@media screen and(max-width: 1140px){
  .palmpay-wrapper {
    .content-wrapper {
      .logos {
        height: 50px;
        .card-logo {
          height: 30px;
        }
        .merchant-logo {
          height: 30px;
        }
      }
      .info {
        height: 70px;
        &.pc {
          display: none;
        }
        &.mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .item {
          height: auto;
          justify-content: space-between;
          margin-bottom: 20px;
          .head {
            font-size: 12px;
          }
          .content {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .order-info {
        padding: 35px 10px;
        margin-bottom: 109px;

        .order-top-tips {
          display: none;
        }

        .bank-name {
          font-weight: 900;
          font-size: 13px;
          color: rgba(39,39,43,0.6);
          line-height: 18px;
          margin-top: 0;
          margin-bottom: 5px;
        }

        .card-logo {
          height: 30px;
          margin-bottom: 30px;
        }

        .ticket-info {
          .ticket-title {
            font-weight: 900;
            font-size: 13px;
            color: rgba(39,39,43,0.6);
            line-height: 18px;
            margin-bottom: 5px;

            .button {
              display: none;
            }
          }
          .ticket-box {
            .button {
              display: inline-block;
            }
          }
          .ticket {
            height: 40px;
            border-radius: 0px;
            font-weight: 900;
            font-size: 18px;
            color: var(--color-primary);
          }
        }
      }

      .bottom-box {
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        .expire-time {
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          margin-bottom: 10px;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}
</style>
